import { Alert, Modal, Skeleton } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../actions/lineup'
import * as accountActions from '../../actions/account'
import * as groupActions from '../../actions/groups'
import * as analysisActions from '../../actions/analysis'
import * as adminActions from '../../actions/admin'
import { EXP_SETTINGS_KEY } from '../../constants/local-storage-keys'
import { getTeamsInSlate } from '../../utils/slate'
import { getLineupSize } from '../../utils/contests'
import getDefaultDateInfo from '../../utils/get-default-date-info'

import { framed, customPresets, allowCheatsheets } from '../../constants/partner'

import NFLOptimizer from '../nfl'
// const NFLOptimizer = React.lazy(() => import('../nfl'))
const NBAOptimizer = React.lazy(() => import('../nba'))
const MLBOptimizer = React.lazy(() => import('../mlb'))

import ReactGA from 'react-ga'
import './lineup-generator.css'
import { isPlayerTableDefault } from '../../utils/is-default'

ReactGA.initialize('UA-146608705-1')

const betweenWeeks = false
const update = false

const MaintenenceText = styled.div`
  color: #FF9966;
  text-align: center;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
`

const StyledLineupGenerator = styled.div`
  padding: ${framed ? '10px 2% 76px' : '100px 2% 76px'};
`

class LineupGenerator extends Component {
  state = {
    tab: 'players',
    slate: 'Main',
    defaultModalOpen: false
  }

  componentDidMount() {
    const { site, slate, sport, contestType } = this.props.match.params

    const params = new URLSearchParams(this.props.location.search)
    let counter = params.get('date')
    let season = params.get('season')

    if (!counter || !season) {
      let [_counter, _season] = getDefaultDateInfo(sport)
      counter = counter || _counter
      season = season || _season
    }

    ReactGA.pageview(`/optimizer/${sport}/${site}/${slate}`)

    this.props.fetchSubscription()
    
    // set 
    this.setState({
      counter,
      season
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { site, slate, sport, contestType } = this.props.match.params

    let showdown = contestType === 'showdown'
    // load player table if new w
    if (prevState.counter !== this.state.counter || prevState.season !== this.state.season) {
      this.props.fetchGroups({site, slate, sport, counter: this.state.counter, season: this.state.season})
      this.props.fetchSettings({ site, sport, showdown, slate, counter: this.state.counter, season: this.state.season })
      this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
      this.props.fetchSavedLineups({site, slate, sport, counter: this.state.counter, season: this.state.season})
      if (allowCheatsheets)
        this.props.fetchCheatsheets({ site, slate, sport, counter: this.state.counter, season: this.state.season })
    }

    if (this.props.opt.loading && !prevProps.opt.loading) {
      this.setState({
        tab: 'lineups'
      })
    }

    if (prevProps.players.loading && !this.props.players.loading) {
      this.props.fetchTeamStacks({
        players: this.props.players.data,
        slate,
        site,
        sport,
        counter: this.state.counter,
        season: this.state.season
      })

      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      if (sport === 'nfl')
        this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
      if (contestType !== 'showdown' || site === 'dk')
        this.props.fetchPresets({ contestType, numTeams, site, sport })

      // only fetch if we are doing custom
      if (customPresets)
        this.props.fetchCustomPresets({ site, slate, sport, counter: this.state.counter, season: this.state.season })
    }

    // Fetch new position counts when settings are loaded
    if (prevProps.settings.loading && !this.props.settings.loading && sport === 'nfl') {
      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
    }

    // Fetch new position counts when num lus is changed
    if (prevProps.settings.data.numLUs !== this.props.settings.data.numLUs && sport === 'nfl') {
      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
    }
  }

  updateSlate(slate, season, counter) {
    const [ slateType, slateName ] = slate.split("|")

    const { site, sport } = this.props.match.params

    // Get query params to pass along (auth purposes)
    const searchParams = new URLSearchParams(window.location.search)
    let queryString = ''
    for (const [key, value] of searchParams.entries()) {
      // don't include slate info
      if (key !== 'date' && key !== 'season')
        queryString += `${key}=${value}&`
    }
    let redirURI = `${window.location.protocol}//${window.location.host}/optimizer/${slateType}/${sport}/${site}/${slateName}${counter && season ? `?date=${counter}&season=${season}` : ''}&${queryString}`

    window.location = redirURI
  }

  updateSite(site) {
    const { sport } = this.props.match.params

    const slate = 'Main'
    const contestType = 'classic'
    // reset to Main slate when we switch sites (slates don't translate across site)
    const searchParams = new URLSearchParams(window.location.search)
    let queryString = ''
    for (const [key, value] of searchParams.entries()) {
      // don't include slate info
      if (key !== 'date' && key !== 'season') {
        queryString += `${key}=${value}&`
      }
    }
    let redirURI = `${window.location.protocol}//${window.location.host}/optimizer/${contestType}/${sport}/${site}/${slate}${`?date=${this.state.counter}&season=${this.state.season}`}&${queryString}`

    window.location = redirURI
  }

  clearPlayerData() {
    const { site, slate, sport, contestType } = this.props.match.params
    let showdown = contestType === 'showdown'
    // Reset default exposures to 0/100
    localStorage.setItem(EXP_SETTINGS_KEY, JSON.stringify({
      zeroed: false
    }))
    // First remove all user settings
    this.props.removePlayers({slate, site, sport, counter: this.state.counter, season: this.state.season})
    // Re-fetch players
    this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
  }

  zeroExposures = () => {
    const { site, slate, sport, contestType } = this.props.match.params
    let showdown = contestType === 'showdown'

    if (this.props.loggedin) {
      // Set default exposures to 0/0
      localStorage.setItem(EXP_SETTINGS_KEY, JSON.stringify({
        zeroed: true
      }))

      this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
    }
    else {
      const _players = this.props.players

      const _playersCleared = _players.data.map(player => {
        const _player = { ...player }
        _player.MinExp = 0
        _player.MaxExp = 0
        return _player
      })

      this.props.updatePlayersTable({payload: _playersCleared, slate, site, sport, counter: this.state.counter, season: this.state.season})
    }
  }

  clearSettingsData() {
    const { site, slate, sport } = this.props.match.params

    this.props.removeTeamStacks({ slate, site, sport, counter: this.state.counter, season: this.state.season })
  }

  getLineups() {
    const { settings, players } = this.props

    // Check to see if there are sufficient changes (per DK guidelines)
    const playerTableDefault = isPlayerTableDefault(players.data)

    if (playerTableDefault) {
      this.setState({defaultModalOpen: true})
    } else {
      const { site, slate, sport } = this.props.match.params
      let contestType = this.props.match.params.contestType || 'classic'

      // Check to see if we have no ownership
      let ownership = false
      this.props.players.data.forEach(p => {
        if (Number(p.ProjOwn || 0) !== 0 || Number(p.UserOwn || 0) !== 0) {
          ownership = true
          return
        }
      })

      let lineupLength = getLineupSize(site, contestType, sport)

      let overrides = {}
      if (!ownership) {
        overrides = {
          minUnderOwn_1: 0,
          minUnderOwn_2: 0,
          minUnderOwn_3: 0,
          minUnderOwn_4: 0,
          minUnderOwn_5: 0,
          maxUnderOwn_1: lineupLength,
          maxUnderOwn_2: lineupLength,
          maxUnderOwn_3: lineupLength,
          maxUnderOwn_4: lineupLength,
          maxUnderOwn_5: lineupLength,
          minTotalOwn: 0,
          maxTotalOwn: (lineupLength * 100)
        }
      }

      const params = { 
        ...settings.data, 
        slate, 
        site, 
        sport, 
        contestType, 
        lineupLength,
        counter: this.state.counter,
        season: this.state.season,
        ...overrides 
      }

      this.props.fetchOpt(params)
    }
  }

  changeTab(tab) {
    this.setState({
      tab
    })
  }

  changeDateInfo({counter, season}) {
    this.setState({
      counter,
      season
    })
  }

  render() {
    const { opt, players, settings } = this.props

    if (!players.data || !settings.data) return false

    const { tab } = this.state
    const { site, slate, sport, contestType } = this.props.match.params

    let body

    switch (sport) {
      case 'nfl':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <NFLOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              week={this.state.counter}
              season={this.state.season}
              changeDateInfo={this.changeDateInfo.bind(this)}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
            />
          </React.Suspense>
        )
        break
      case 'nba':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <NBAOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
              season={this.state.season}
              counter={this.state.counter}
              changeDateInfo={this.changeDateInfo.bind(this)}
            />
          </React.Suspense>
        )
        break
      case 'mlb':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <MLBOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              season={this.state.season}
              counter={this.state.counter}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
              changeDateInfo={this.changeDateInfo.bind(this)}
            />
          </React.Suspense>
        )
        break
      default:
        body = false
    }

    return (
      <StyledLineupGenerator className="LineupGenerator">
        <Modal
            title="Player Table must be modified!"
            centered
            open={this.state.defaultModalOpen}
            onOk={() => {this.setState({defaultModalOpen: false})}}
            onCancel={() => {this.setState({defaultModalOpen: false})}}
            footer={null}
          >
            <p>
                Community Guidelines require users to modify their player table before using a lineup building tool. <br/><br/>
                You can do this by: <br/><br/>
                - Adjusting a player's exposure<br/>
                - Adjusting a player's projected point total<br/>
                - Adjusting a player's projected ownership<br/><br/>
                Thank you for your understanding!
            </p>
          </Modal>
        {
          update && !this.props.loggedin ? (
            <Alert
              message="Introducting Accounts! Save your settings across devices and create a list of Saved lineups from different builds. Sign up for Free."
              type="info"
              closable
              style={{margin: '20px'}}
            />
          ) : (
            ''
          )
        }
        {
          betweenWeeks ? (
            <MaintenenceText>
              Week 5 Slates will be released Wednesday
            </MaintenenceText>
          ) : ''
        }
        <div className="LineupGenerator-body">
          { body }
        </div>
      </StyledLineupGenerator>
    )
  }
}

export default connect(
  state => ({
    opt: state.lineup.opt,
    players: state.lineup.players,
    settings: state.lineup.settings,
    teamStacks: state.lineup.teamStacks,
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    socket: state.sockets,
    subscription: state.subscription
  }),
  {
    fetchOpt: lineupActions.fetchOpt,
    fetchPlayers: lineupActions.fetchPlayers,
    fetchTeamStacks: lineupActions.fetchTeamStacks,
    fetchSettings: lineupActions.fetchSettings,
    fetchPresets: lineupActions.fetchPresets,
    fetchCustomPresets: adminActions.fetchCustomPresets,
    removePlayers: lineupActions.removePlayers,
    cancelOpt: lineupActions.cancelOpt,
    fetchSavedLineups: lineupActions.fetchSavedLineups,
    updatePlayersTable: lineupActions.updatePlayersTable,
    removeTeamStacks: lineupActions.removeTeamStacks,
    fetchSubscription: accountActions.fetchSubscription,
    fetchGroups: groupActions.fetchGroups,
    fetchPositionCounts: analysisActions.fetchPositionCounts,
    fetchCheatsheets: adminActions.fetchCheatsheets
  }
)(LineupGenerator)
