const VERSION = 1

// Get which NFL week we are in
export const week = (() => {
  const _date = new Date()

  const nflStartDate = new Date('2023-09-05T12:00:00')
  if (_date < nflStartDate) return 1
  const m = Math.round((_date-nflStartDate)/(1000*60*60*24)) / 7
  return Math.floor(m) + 1

  // return 1
})()
// export const week = 13

export const season = 2023

export const weekOptions = Array.from({length: week}, (_, i) => i + 1)
// export const weekOptions = [1]

export const seasonOptions = [2023]